import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import makesenseIcon from "../images/jobs_that_makesense.png"

type JobsCardProps = {
  makesenseID: string
  className: string
}

const JobsCard = ({ makesenseID, className }: JobsCardProps) => {
  function markclick() {
    window.dataLayer.push({ event: "job_click", jobb: "JTMS" })
  }

  let output
  if (makesenseID !== null) {
    output = (
      <div className={"text-primary relative  " + className}>
        <h2 className="text-xl font-bold mb-2 whitespace-no-wrap">
          Offres d'emploi sur&nbsp;:
        </h2>
        <ul className="my-4">
          <li className="">
            <a
              className="flex font-sans text-shift text-sm font-bold my-2 flex items-center transition duration-300 ease hover:underline"
              target="_blank"
              rel="noreferrer"
              onClick={markclick}
              href={`https://jobs.makesense.org/projects/${makesenseID}`}
            >
              <img
                className="mr-2"
                src={makesenseIcon}
                style={{ height: "24px", width: "auto" }}
              />
              <span className="">jobs_that_makesense</span>
            </a>
          </li>
        </ul>
      </div>
    )
  } else {
    output = ""
  }
  return output
}

export default JobsCard
