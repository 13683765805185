import {
  DispatchFormType,
  Lever,
  OneOrganizationType,
  Organization,
} from "../utils/model"
import { Link, graphql } from "gatsby"
import React, { useEffect } from "react"

import Breadcrumb from "../components/Breadcrumb"
import JobsCard from "../components/JobsCard"
import Layout from "../components/layout"
import LeversCard from "../components/LeversCard"
import LinkedInPicto from "../images/linkedin.svg"
import OrgFeatures from "../components/OrgFeatures"
import SEO from "../components/seo"
import WebsitePicto from "../images/website.svg"
import { capitalize } from "lodash"
import { getOrganizationFields } from "../utils/meilisearch"
import { markFormClick } from "../utils/forms"
import { useCategoryTree } from "../utils/custom-hooks"

type OrganizationTemplateProps = {
  data: {
    organization: OneOrganizationType
    form: DispatchFormType
  }
  pageContext: { organizationId }
}

function OrganizationTemplate(props: OrganizationTemplateProps) {
  let descRaw = ""
  useEffect(() => {
    document.body.className = "page-organization"
    // remove HMTL in string
    let div = document.createElement("div")
    div.innerHTML = organization.desc
    descRaw = div.textContent ?? div.innerText ?? ""
  }, [])

  const markclick = markFormClick

  const allCategories = useCategoryTree()
  const raw = props.data.organization
  const rawForm = props.data.form

  const categories = raw.data.Categories
    ? raw.data.Categories.map(c => allCategories.find(c.data.Order))
    : []

  const levers = raw.data.Lever
    ? raw.data.Lever.map(lever => {
        let pictoURL = null
        if (lever.data.LeverPictoURL) {
          pictoURL = lever.data.LeverPictoURL
        }

        return new Lever(
          lever.id,
          lever.data.Name,
          lever.data.Label?.childMarkdownRemark.html,
          pictoURL
        )
      })
    : []

  const organization = new Organization(
    getOrganizationFields(raw, {
      levers,
      website: raw.data.Website,
      linkedin: raw.data.Linkedin,
      effectif: raw.data.Workforce,
      structure: raw.data.StructureType,
      trainings: raw.data.Trainings?.map(t => capitalize(t.data.Training)),
      professions: raw.data.Professions?.map(t =>
        capitalize(t.data.Profession)
      ),
    })
  )

  const seo_organization_name = organization.name.split(" - ")[0]
  const title = `Comment ${seo_organization_name} oeuvre pour le climat`
  descRaw = organization.desc
  const edit_link = `https://airtable.com/${rawForm.data.Form}?prefill_InputOrgName=${organization.name}`

  return (
    <Layout contentClassName="bg-gray-100 ">
      <SEO
        title={title}
        description={descRaw}
        gtmPageType="organization"
        gtmPageName={`organization - ${organization.name}`}
      />

      <div className="">
        <div className="px-2 pt-6 container md:mx-auto md:max-w-2xl  lg:max-w-3xl">
          <p className="text-xs font-sans text-gray-700 mt-4 uppercase tracking-widest lg:max-w-lg ">
            Organisation
          </p>
          <h1 className=" text-3xl xl:text-5xl leading-none text-primary font-black mb-5">
            {organization.name}
          </h1>
          {categories.length && <Breadcrumb category={categories[0]} />}
        </div>

        <OrgFeatures
          organization={organization}
          className="px-2 font-sans border-t border-b py-3 md:mx-auto md:max-w-2xl  lg:max-w-3xl text-shift text-2xs lg:text-xs"
        />

        {(organization.linkedin || organization.website) && (
          <div className="px-2 flex flex-wrap text-shift text-xs font-sans font-bold my-1 pt-1 pb-2 border-b md:mx-auto md:max-w-2xl  lg:max-w-3xl">
            {organization.website && (
              <div className="flex items-center mr-5 ">
                <img src={WebsitePicto} className="mr-1 " />
                <a href={organization.website.toString()} target="_blank">
                  {organization.website.host}
                </a>
              </div>
            )}

            {organization.linkedin && (
              <div className="flex items-center mr-5 ">
                <img src={LinkedInPicto} className="mr-1 " />
                <a href={organization.linkedin.toString()} target="_blank">
                  LinkedIn
                </a>
              </div>
            )}
          </div>
        )}
        <div className="px-2 md:container md:mx-auto md:max-w-2xl lg:max-w-3xl flex flex-col md:flex-row">
          <div className="md:pr-5 lg:pr-10 my-4 flex flex-col md:w-7/12 lg:w-8/12">
            {/* <LeversCard organization={organization} className="p-10 mb-8 ml-8 border-4 border-secondary hidden md:block md:float-right " /> */}
            <div
              className="markdown break-word"
              dangerouslySetInnerHTML={{
                __html: organization.desc,
              }}
            />
            <hr className="mt-3 mb-5" />
            <div className="max-w-3xl">
              <p className="text-sm font-sans font-bold  text-primary">
                Mots clés associés
              </p>
              <div
                className="text-sm "
                dangerouslySetInnerHTML={{
                  __html: `<span class="capitalize-first-letter inline-block">${organization.tags.join(
                    "</span> - <span class='inline-block capitalize-first-letter'>"
                  )} </span>`,
                }}
              ></div>
            </div>
            <hr className="mt-5" />

            {organization.professions?.length > 0 && (
              <div className="mt-6 mb-6">
                {" "}
                Exemples de métiers et formations représentés, renseignés via
                une contribution ou, par défaut, tels qu'indiqués sur la page
                Linkedin de l'organisation :
              </div>
            )}

            {organization.professions?.length > 0 &&
              organization.trainings?.length > 0 && (
                <div>
                  <div className="text-sm font-sans font-bold  text-primary">
                    Metiers
                  </div>
                  <div className="text-sm">
                    {organization.professions.join(" - ")}
                  </div>
                  {/* <ul className="font-sm flex flex-wrap" style={{marginTop: 0, marginBottom:"15px"}}>
                                    {organization.professions.map(tr => (
                                        <li style={{marginRight: "5px"}}>{tr}</li>
                                    ))}
                                </ul> */}
                </div>
              )}

            {organization.trainings?.length > 0 && (
              <div>
                <div className="mt-5 text-sm font-sans font-bold  text-primary">
                  Formations
                </div>
                <div className="text-sm">
                  {organization.trainings.join(" - ")}
                </div>
                {/* <ul className="font-sm  flex flex-wrap" style={{marginTop: 0, marginBottom:"15px"}}>
                                    {organization.trainings.map(tr => (
                                        <li style={{marginRight: "5px"}}>{tr}</li>
                                    ))}
                                </ul> */}
              </div>
            )}
            <hr className="mt-8" />
          </div>
          <div className="md:w-5/12 lg:w-4/12 mb-8 mt-4">
            <LeversCard
              organization={organization}
              className="px-6 py-6   border-4 border-secondary"
            />
            <JobsCard
              makesenseID={organization.idMakeSense}
              className="px-6 py-6 border-4 border-t-0 border-secondary"
            />
          </div>
        </div>
        <div className="px-3 md:mx-auto md:max-w-2xl lg:max-w-4xl xl:max-w-5xl 2xl:max-w-6xl pt-6 pb-16 md:px-0">
          <div className="container md:mx-auto max-w-3xl ">
            <small className="block mt-8 font-sans font-bold font-sm mb-8">
              Dans l’esprit collaboratif qui a été au cœur de ce projet, vous
              pouvez suggérer des ajouts ou des modifications. Ces suggestions
              seront soumises au même processus de validation qu’à la création
              de la base de données.
            </small>
            <a
              href={edit_link}
              target="_blank"
              rel="noreferrer"
              onClick={() => markclick(rawForm.data.Name)}
              className="inline-flex no-underline font-sans font-bold text-sm bg-transparent rounded border-2 border-secondary text-primary text-center px-6 py-3 transition duration-300 ease transition-colors hover:bg-secondary hover:text-primary "
            >
              Suggérer une modification
            </a>
          </div>
        </div>

        {organization.categories.length > 0 && (
          <div className="bg-primary text-white relative p-10 mt-16 mb-20 text-center">
            <h2 className="text-2xl font-black mb-4">
              En savoir plus sur les secteurs associés
            </h2>

            <ul className="flex justify-center items-center flex-wrap 2xl:mx-10">
              {organization.categories.map(cat => (
                <li key={cat.id} className="font-sans mx-2">
                  <Link to={cat.slug}>
                    <div className="no-underline leading-snug text-white font-bold rounded border border-gray-600 px-4 py-2 mt-4  capitalize-first-letter transition duration-300 ease hover:border-white inline-flex items-center">
                      <span className="text-sm capitalize-first-letter ">
                        {cat.name}
                      </span>
                      <span className="bg-gray-600 h-4 w-px top-0 left-0 mx-2"></span>
                      <span className="text-sm">{cat.newOrgCount}</span>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>

            <Link
              to="/secteurs"
              className="inline-flex items-center no-underline leading-snug text-secondary font-bold rounded border border-secondary px-4 py-2 mt-4 transition duration-300 ease transition-colors hover:text-white"
            >
              <span className=" font-sans text-sm capitalize-first-letter">
                Tous les secteurs
              </span>
            </Link>
          </div>
        )}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query OrganizationQuery($organizationId: String) {
    form: airtable(table: { eq: "DispatchForm" }, rowIndex: { eq: 2 }) {
      data {
        Name
        Form
      }
    }
    organization: airtable(
      table: { eq: "Organization" }
      id: { eq: $organizationId }
    ) {
      id
      data {
        Name
        ActivityDescription {
          childMarkdownRemark {
            html
          }
        }
        Categories {
          id
          data {
            Name
            Order
          }
        }
        JobBoardOrgID
        StructureType
        CreationYear
        Workforce
        Headquarter {
          id
          data {
            CityName
            GPSCoordinates
            DepartmentName
            RegionName
            Country
            Dpt2
          }
        }
        City {
          id
          data {
            CityName
            GPSCoordinates
            DepartmentName
            RegionName
            Country
            Dpt2
          }
        }
        Linkedin
        Website
        Tags {
          id
          data {
            Tag
          }
        }
        Lever {
          id
          data {
            Name
            LeverPictoURL
            Picto {
              localFiles {
                url
              }
            }
          }
        }
        Trainings {
          id
          data {
            Training
          }
        }
        Professions {
          id
          data {
            Profession
          }
        }
      }
    }
  }
`

export default OrganizationTemplate
